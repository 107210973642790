<template>
  <v-form ref="serviceSubscriptionRequestForm">
    <v-card class="pa-6 my-8">
      <!-- <v-alert
        type="warning"
        show-icon
        class="mb-6"
        outlined
        border="left"
        v-if="isExpired"
      >
        <div style="color: black; font-weight: 500">
          The subscription request should be approved or rejected at least one day before the
          expiration date
        </div>
      </v-alert> -->
      <AdsTextArea
        v-model="justificationValue"
        label="Order Number/ Other"
        maxlength="200"
        counter="200"
        :rules="[validators.required]"
        :disabled="true"
      />
      <AdsTextField
        v-model="serviceSubscriptionRequest.supplierName"
        label="Supplier"
        :disabled="true"
        :rules="[validators.required]"
      />
      <AdsSelect
        :multiple="true"
        v-model="serviceSubscriptionRequest.productNames"
        :items="serviceSubscriptionRequest.productNames"
        class="mb-0"
        outlined
        label="Supplier Products"
        :disabled="true"
        :rules="[validators.required]"
      />
      <AdsSelect
        v-model="serviceSubscriptionRequest.isFinancial"
        :items="[
          { value: true, text: 'Yes' },
          { value: false, text: 'No' },
        ]"
        outlined
        label="Finance Exclusive"
        :disabled="true"
      />
      <AdsTextField
        v-model="serviceSubscriptionRequest.subscriber"
        label="Subscriber"
        :disabled="true"
        :rules="[validators.required]"
      />
      <AdsTextField
        v-model="serviceSubscriptionRequest.status"
        label="Status"
        :disabled="true"
        :rules="[validators.required]"
      />
      <DatePicker
        v-model="serviceSubscriptionRequest.startDate"
        label="From"
        :disabled="true"
        :rules="[validators.required]"
      />
      <DatePicker
        v-model="serviceSubscriptionRequest.endDate"
        label="To"
        :rules="endDateRules"
        @input="durationUpdate"
      />
      <AdsTextField
        v-model="serviceSubscriptionRequest.duration"
        label="Duration"
        :disabled="true"
        :rules="[validators.required]"
      />
      <AdsTextArea
        v-model="serviceSubscriptionRequest.comments"
        label="Supplier Comments"
        maxlength="5000"
        counter="5000"
        :disabled="true"
      />
      <AdsTextArea
        v-model="serviceSubscriptionRequest.rejectReason"
        label="Admin Additional Reject Comments for Suppliers"
        maxlength="5000"
        counter="5000"
        :disabled="true"
        placeholder="comments"
      />

      <v-row>
        <v-col cols="9">
          <AdsTextArea
            v-model="serviceSubscriptionRequest.adminComment"
            label="Admin Review Comments"
            maxlength="5000"
            counter="5000"
            :disabled="isReadOnlyUser"
          />
        </v-col>
        <v-col
          cols="3"
          class="d-flex flex-column"
        >
          <v-btn
            large
            color="primary"
            buttonText="Save"
            class="mb-2"
            :disabled="isReadOnlyUser || isLoading"
            :loading="isLoading"
            @click="saveAdminComment"
          >
            <v-icon class="mr-3">mdi-content-save</v-icon>
            Save
          </v-btn>
          <v-btn
            large
            color="primary"
            buttonText="Save"
            :disabled="isReadOnlyUser || isLoading"
            @click="serviceSubscriptionRequest.adminComment = ''"
          >
            Clear
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <AdsButton
      :primary="true"
      buttonText="Approve"
      class="mr-4"
      @click="approve"
      :readonly="isReadOnlyUser"
      :disabled="isShouldDisableIfApprovedOrRejected || isReadOnlyUser || isLoading"
    />
    <AdsButton
      :primary="true"
      buttonText="Reject"
      class="mr-4"
      @click="reject"
      :readonly="isReadOnlyUser"
      :disabled="isShouldDisableIfApprovedOrRejected || isReadOnlyUser || isLoading"
    />
    <Dialog
      :openDialog="openDialog"
      title="Subscription Request Reject"
      maxWidth="520px"
      icon="security"
      :displayCloseBtn="true"
      @close="handleClose"
    >
      <template v-slot:text>
        <div>
          <AdsTextField
            class="pl-6 pr-6"
            v-model="serviceSubscriptionRequest.rejectReason"
            label="Reject Reason"
            ref="rejectReason"
            :error="!!rejectReasonErrors"
          />
          <p
            style="color: #d7153a"
            class="ml-6"
          >
            {{ rejectReasonErrors }}
          </p>
        </div>
      </template>
      <template v-slot:actions>
        <AdsButton
          :primary="true"
          buttonText="Reject"
          class="ml-6 mb-6"
          @click="rejectSubmit"
        />
      </template>
    </Dialog>
  </v-form>
</template>
<script>
import {
  AdsTextField,
  AdsButton,
  AdsTextArea,
  AdsSelect,
  DatePicker,
  Dialog,
} from '@nswdoe/doe-ui-core'
import { DEFAULT_SERVICE_SUBSCRIPTION } from '@/constants/initial'
import validators from '@/helpers/validators'
import { getFormErrors } from '@/helpers/form'
import dayjs from 'dayjs'
import { get } from 'lodash'

export default {
  name: 'ViewServiceSubscriptionRequest',
  components: {
    AdsTextField,
    AdsTextArea,
    AdsSelect,
    DatePicker,
    AdsButton,
    Dialog,
  },
  props: {
    initialServiceSubscriptionRequest: {
      type: Object,
      default: DEFAULT_SERVICE_SUBSCRIPTION,
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin
    },
    isReadOnlyUser() {
      return this.$store.state.isReadOnlyUser
    },
    justificationValue() {
      return get(this.serviceSubscriptionRequest, 'justification.value', '')
    },
    isShouldDisableIfApprovedOrRejected() {
      return (
        this.serviceSubscriptionRequest.status === 'Approved' ||
        this.serviceSubscriptionRequest.status === 'Rejected'
        // || this.isExpired
      )
    },
    isExpired() {
      return this.isDateBeforeOrEqualToday(this.serviceSubscriptionRequest.endDate)
    },
  },
  data() {
    return {
      serviceSubscriptionRequest: this.initialServiceSubscriptionRequest,
      errors: [],
      rejectReasonErrors: '',
      showErrors: false,
      validators: {
        required: validators.required,
        alpha: validators.alpha,
        phone: validators.phone,
        email: validators.email,
        alphaNumeric: validators.alphaNumeric,
        isValidURL: validators.isValidURL,
      },
      endDateRules: [validators.required],
      openDialog: false,
      isLoading: false,
    }
  },
  methods: {
    durationUpdate() {
      if (this.serviceSubscriptionRequest.endDate) {
        let endDate
        if (this.serviceSubscriptionRequest.endDate.indexOf('-') !== -1) {
          endDate = dayjs(this.serviceSubscriptionRequest.endDate, 'YYYY-MM-DD')
        } else {
          endDate = dayjs(this.serviceSubscriptionRequest.endDate, 'DD/MM/YYYY')
        }
        const startDate = dayjs(this.serviceSubscriptionRequest.startDate, 'YYYY-MM-DD')
        this.serviceSubscriptionRequest.duration = dayjs(endDate).diff(startDate, 'days')
        if (this.serviceSubscriptionRequest.duration <= 0) {
          this.endDateRules = [
            validators.required,
            () => 'The end Date can not before the start date.',
          ]
        } else {
          this.endDateRules = [validators.required]
        }
        this.serviceSubscriptionRequest.duration =
          this.serviceSubscriptionRequest.duration + ' days'
      }
    },
    handleClose() {
      this.rejectReasonErrors = ''
      this.serviceSubscriptionRequest.rejectReason = ''
      this.openDialog = false
    },
    cancel() {
      if (window.history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push('/serviceregistry/subscriptionrequests')
      }
    },
    saveAdminComment() {
      this.isLoading = true
      const payload = {
        adminComment: this.serviceSubscriptionRequest.adminComment,
        subscriptionReqId: this.serviceSubscriptionRequest.subscriptionReqId,
        supplierId: this.serviceSubscriptionRequest.supplierId,
        updateAdminComment: true,
      }
      this.$emit('save', payload)
      this.isLoading = false
    },
    approve() {
      this.validate()
      this.errors = getFormErrors(this.$refs.serviceSubscriptionRequestForm)

      if (this.errors && this.errors.length > 0) {
        this.showErrors = true
      } else {
        let serviceSubscriptionRequestBodyRequest = {
          comments: this.serviceSubscriptionRequest.comments,
          adminComment: this.serviceSubscriptionRequest.adminComment,
          subscriptionReqId: this.serviceSubscriptionRequest.subscriptionReqId,
          supplierId: this.serviceSubscriptionRequest.supplierId,
          endDate: dayjs(this.serviceSubscriptionRequest.endDate, 'YYYY-MM-DD').format(
            'DD/MM/YYYY'
          ),
          status: 'Approved',
        }
        this.$emit('save', serviceSubscriptionRequestBodyRequest)
      }
    },
    reject() {
      this.validate()
      this.errors = getFormErrors(this.$refs.serviceSubscriptionRequestForm)

      if (this.errors && this.errors.length > 0 && this.errors[0].label !== 'Reject Reason') {
        this.showErrors = true
      } else {
        // No validation errors
        this.openDialog = true
        //this.$emit('save', this.serviceSubscriptionRequest)
      }
    },
    rejectSubmit() {
      const rejectReason = this.serviceSubscriptionRequest.rejectReason

      if (!rejectReason) {
        this.rejectReasonErrors = 'Reject Reason is required'
        this.showErrors = true
      } else {
        this.rejectReasonErrors = ''
        let serviceSubscriptionRequestBodyRequest = {
          comments: this.serviceSubscriptionRequest.comments,
          adminComment: this.serviceSubscriptionRequest.adminComment,
          subscriptionReqId: this.serviceSubscriptionRequest.subscriptionReqId,
          supplierId: this.serviceSubscriptionRequest.supplierId,
          endDate: dayjs(this.serviceSubscriptionRequest.endDate, 'YYYY-MM-DD').format(
            'DD/MM/YYYY'
          ),
          rejectReason: this.serviceSubscriptionRequest.rejectReason,
          status: 'Rejected',
        }
        this.$emit('save', serviceSubscriptionRequestBodyRequest)
        this.openDialog = false
      }
    },
    validate() {
      if (this.$refs.serviceSubscriptionRequestForm) {
        this.$refs.serviceSubscriptionRequestForm.validate()
      }
    },
    isDateBeforeOrEqualToday(date) {
      const today = dayjs().startOf('day') // Get today's date at midnight
      const end = dayjs(date).startOf('day') // Ensure comparison ignores time

      return end.isBefore(today) || end.isSame(today)
    },
  },
}
</script>
