<template>
  <div id="view-service-subscription-request">
    <v-container>
      <div class="d-flex align-center space-between mt-4">
        <h1 class="mt-4">Subscription Request Details</h1>
        <AdsButton
          class="ml-auto"
          @click="cancel"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </AdsButton>
      </div>
      <v-alert
        dismissible
        outlined
        text
        :color="saveClicked.type"
        v-model="saveClicked.isClicked"
        class="mt-4"
      >
        <div v-if="!saveClicked.isOverlapping">
          {{ saveClicked.message }}
        </div>
        <div
          v-else
          style="color: '#232323'"
        >
          <div>Another supplier already holds an active 'Finance Exclusive' subscription.</div>
          <div>
            <ul class="mt-4">
              <li
                v-for="sub in saveClicked.overLappingSubs"
                :key="sub.subscriptionReqId"
              >
                Click
                <router-link
                  class="ml-1 mr-1"
                  :to="{
                    path: `/serviceregistry/subscriptions/view/${sub.subscriptionId}`,
                    query: { supplierId: sub.supplierId },
                  }"
                >
                  here
                </router-link>
                to view the subscription.
              </li>
            </ul>
          </div>
        </div>
      </v-alert>
      <ServiceSubscriptionRequestForm
        :initial-service-subscription-request="serviceSubscriptionRequest"
        @save="updateServiceSubscriptionRequest"
        v-if="serviceSubscriptionRequest?.subscriptionReqId"
      />
    </v-container>
  </div>
</template>
<script>
import { AdsButton } from '@nswdoe/doe-ui-core'
import { cloneDeep } from 'lodash'
import ServiceSubscriptionRequestForm from '@/views/SubscriptionRequests/form.vue'
import { DEFAULT_SERVICE_SUBSCRIPTION_REQUEST } from '@/constants/initial'
import dayjs from 'dayjs'

export default {
  name: 'UpdateServiceSubscriptionRequest',
  title: 'Update Service Subscription Request - SAIS Operation UI Portal',
  components: {
    AdsButton,
    ServiceSubscriptionRequestForm,
  },
  data() {
    return {
      serviceSubscriptionRequest: cloneDeep(DEFAULT_SERVICE_SUBSCRIPTION_REQUEST),
      saveClicked: {
        isClicked: false,
        isSuccess: false,
        message: '',
        isOverlapping: false,
        overLappingSubs: [],
      },
    }
  },

  created() {
    if (
      !this.$store.state.moduleSchools.schools ||
      this.$store.state.moduleSchools.schools.length === 0
    ) {
      this.$store.dispatch('moduleSchools/fetchSchools')
    }

    this.$store
      .dispatch('moduleSubscriptionRequests/fetchSubscriptionRequest', {
        requestId: this.$route.params.id,
        supplierId: this.$route.query.supplierId,
      })
      .finally(() => {
        const serviceSubscriptionRequest =
          this.$store.state.moduleSubscriptionRequests.selectedSubscriptionRequest
        const selectedSchool = this.$store.state.moduleSchools.schools.find(
          (school) => school.code === serviceSubscriptionRequest.schoolCode
        )
        serviceSubscriptionRequest.subscriber = selectedSchool?.name
        serviceSubscriptionRequest.duration =
          dayjs(serviceSubscriptionRequest.endDate).diff(
            serviceSubscriptionRequest.startDate,
            'days'
          ) + ' days'

        this.serviceSubscriptionRequest = serviceSubscriptionRequest
      })
  },

  methods: {
    cancel() {
      if (window.history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push('/serviceregistry/subscriptionrequests')
      }
    },
    async updateServiceSubscriptionRequest(item) {
      const response = await this.$store.dispatch(
        'moduleSubscriptionRequests/putSubscriptionRequests',
        item
      )
      const isOverlapping = response?.isError && response?.errorCode === 409

      this.saveClicked = {
        isClicked: true,
        isOverlapping,
        isSuccess: response.isError ? false : true,
        type: response.isError ? 'error' : 'success',
        message: response.isError
          ? response.errorMessage
          : 'The changes have been updated successfully',
        overLappingSubs: response?.overlappingSubs || [],
      }

      if (item.updateAdminComment) return

      if (!response.isError) {
        this.$router.push('/serviceregistry/subscriptionrequests')
      }
    },
  },
}
</script>
